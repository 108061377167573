import { createClient } from 'viem';
import { cookieStorage, createConfig, createStorage, http } from 'wagmi';
import {
  arbitrum,
  aurora,
  avalanche,
  base,
  bsc,
  fantom,
  gnosis,
  klaytn,
  mainnet,
  optimism,
  polygon,
  zkSync,
} from 'wagmi/chains';
import { injected, walletConnect } from 'wagmi/connectors';

import { WALLET_CONNECT_PROJECT_ID } from '@shared/constants';
import { GENERAL_URLS } from '@shared/constants/url';

import { CoreTypes } from '@walletconnect/types';

if (!WALLET_CONNECT_PROJECT_ID) {
  throw new Error('Wallet Connect Project ID is not defined');
}

const metadataConfig = {
  'store-web': {
    name: 'Magic Store',
    description: 'Crypto App Store by Magic Square',
    url: 'https://magic.store',
    icons: ['https://magicsquare.io/wp-content/uploads/2022/06/MSQ-logo-short.svg'],
  },
  staking: {
    name: 'Magic Staking',
    description: 'Crypto App Store by Magic Square',
    url: GENERAL_URLS.staking,
    icons: ['https://magicsquare.io/wp-content/uploads/2022/06/MSQ-logo-short.svg'],
  },
  launchpad: {
    name: 'Magic Launchpad',
    description: 'Crypto App Store by Magic Square',
    url: 'https://launchpad.magicsquare.io',
    icons: ['https://magicsquare.io/wp-content/uploads/2022/06/MSQ-logo-short.svg'],
  },
  empty: {
    name: 'Magic Square',
    description: 'Magic Square',
    url: 'https://magicsquare.io',
    icons: ['https://magicsquare.io/wp-content/uploads/2022/06/MSQ-logo-short.svg'],
  },
} as const satisfies Record<string, CoreTypes.Metadata>;

export const PROJECT_NAME =
  typeof process !== 'undefined'
    ? (process.env.NEXT_PUBLIC_PROJECT_NAME as keyof typeof metadataConfig)
    : undefined;

const metadata =
  (PROJECT_NAME && PROJECT_NAME in metadataConfig && metadataConfig[PROJECT_NAME]) ||
  metadataConfig.empty;

declare module 'wagmi' {
  interface Register {
    config: typeof wagmiConfig;
  }
}

export const wagmiConfig = createConfig({
  // ssr: true,
  storage: createStorage({
    storage: cookieStorage,
  }),
  syncConnectedChain: false,
  multiInjectedProviderDiscovery: true,
  chains: [
    mainnet,
    bsc,
    polygon,
    optimism,
    arbitrum,
    gnosis,
    avalanche,
    fantom,
    aurora,
    zkSync,
    klaytn,
    base,
  ],
  client({ chain }) {
    return createClient({
      chain,
      transport: http(undefined, {
        timeout: 30_000,
        batch: {
          batchSize: 7,
          wait: 150,
        },
      }),
    });
  },
  connectors: [
    injected({ shimDisconnect: true }),
    walletConnect({
      projectId: WALLET_CONNECT_PROJECT_ID,
      metadata,
      qrModalOptions: {
        themeMode: 'dark',
      },
      showQrModal: false,
    }),
  ],
});

export type WagmiConfigChain = (typeof wagmiConfig)['chains'][number];

// TODO: we need to implement siwe using web3modal
// function createMessage({ nonce, address, chainId }: SIWECreateMessageArgs){
//   const message = new SiweMessage({
//     version: '1',
//     domain: window.location.host,
//     uri: window.location.origin,
//     address,
//     chainId,
//     nonce,
//     statement: 'Sign in With Ethereum.'
//   })

//   return message.prepareMessage()
// }

// const siweConfig = createSIWEConfig({
//   createMessage,
//   getNonce: () => getSIWENonce({}),
//   getSession: async () => {
//     const user = await getUserData();

//     // user.wallets[0].attributes.
//     return {
//       // address: user.wallets.

//     }
//   },
//   signOutOnDisconnect: true,
//   signOutOnAccountChange: true,
//   verifyMessage,
//   signOut
// })
